<template>
  <b-container fluid class="report-balance-subtypes-doughnut-chart-wrapper">
    <template v-if="!apiData || Array.isArray(this.apiData) || Object.keys(apiData).length === 0">
      <empty-graph :message="$t('incomes_expenses.accounts.view.no_transaction_no_chart')"/>
    </template>
    <template v-else>
      <doughnut-chart
        :chart-data="chartData"
        :options="chartOptions"
        :chart-id="'report-balance-subtypes-doughnut-chart'"
        :width="100"
        :height="400"
        :css-classes="''"
        :plugins="[]"
        v-if="bOkToLoad"
      >
      </doughnut-chart>
    </template>

  </b-container>
</template>

<script>
import DoughnutChart from '@/components/chartjs/DoughnutChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import EmptyGraph from '@/components/common/EmptyGraph'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
// import chroma from 'chroma-js'

export default {
  name: 'AssetLiabilitySubtypesReportDoughnutChart',
  props: ['apiData', 'accountType'],
  mixins: [chartOptions],
  components: {
    DoughnutChart,
    EmptyGraph
  },
  data () {
    return {
      chartData: null,
      bOkToLoad: true
    }
  },
  created () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    },
    accountType () {
      this.fillChart()
    },
    currentLang () {
      this.fillChart()
    }
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  },
  methods: {
    fillChart () {
      this.bOkToLoad = true
      this.chartOptions.legend.display = true

      /* Modify the Y-Axis */
      this.chartOptions.scales.yAxes[0].display = false
      this.chartOptions.scales.xAxes[0].display = false

      /* Make half circle */
      this.chartOptions.rotation = -Math.PI
      this.chartOptions.circumference = Math.PI

      const labelData = []
      const ratioData = []
      const tmpLabelSortedData = []
      // let color01 = ''
      // let color02 = ''
      const aOriginalsColors = ['#FEC600', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c']
      const aColors = []

      if (Object.prototype.hasOwnProperty.call(this.apiData, this.accountType)) {
        /**
         * Sort in two steps to get our primary colors to the sections with highest amount
         */
        for (const index in this.apiData[this.accountType].subtype_sums) {
          tmpLabelSortedData.push({
            label: this.$t('common.asset_liabilities.subtypes_plural.' + index),
            amount: parseInt(this.apiData[this.accountType].subtype_sums[index])
          })
        }
        tmpLabelSortedData.sort((a, b) => a.amount < b.amount ? 1 : -1)

        for (const index in tmpLabelSortedData) {
          labelData.push(tmpLabelSortedData[index].label)
          ratioData.push(tmpLabelSortedData[index].amount)
          aColors.push(aOriginalsColors.shift())
        }

        /* Modify the tooltip onmoauseover */
        this.chartOptions.tooltips.callbacks.label = function (tooltipItem, chartData) {
          return chartData.labels[tooltipItem.index] + ': ' + formatNumberToFull(chartData.datasets[0].data[tooltipItem.index]).split(' ').join('') + ' kr'
        }

        const datasetAssets = {
          data: ratioData,
          backgroundColor: aColors
          // backgroundColor: chroma.scale([color01, color02]).gamma(2).colors(ratioData.length),
          // borderColor: chroma.scale([chroma(color01).brighten(3).hex(), chroma(color02).brighten(3).hex()]).gamma(2).colors(ratioData.length)
        }

        this.chartData = {
          labels: labelData,
          datasets: [datasetAssets]
        }
      } else {
        this.bOkToLoad = false
      }
    }

  }
}
</script>

<style lang="scss">
  .report-balance-subtypes-doughnut-chart{

  }
</style>
