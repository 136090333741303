<template>
  <b-container fluid class="report-balance-subtypes-doughnut-chart-wrapper">
    <template v-if="!apiData || Array.isArray(this.apiData) || Object.keys(apiData).length === 0">
      <empty-graph :message="$t('incomes_expenses.accounts.view.no_transaction_no_chart')"/>
    </template>
    <template v-else>
      <sunburst :data="chartData" :key="accountType ? accountType + '_' + currentLang : 'chartData' + '_' + currentLang"/>
    </template>
  </b-container>
</template>

<script>
import EmptyGraph from '@/components/common/EmptyGraph'
import sunburst from '@/components/d3charts/sunburst/sunburst.vue'
import chroma from 'chroma-js'
// import _ from 'lodash'

export default {
  name: 'AssetLiabilityDetailedReportDoughnutChart',
  props: ['apiData', 'accountType'],
  components: {
    sunburst,
    EmptyGraph
  },
  data () {
    return {
      chartData: []
    }
  },
  created () {
    this.fillChart()
  },
  watch: {
    apiData () {
      this.fillChart()
    },
    currentLang () {
      this.fillChart()
    },
    accountType () {
      this.fillChart()
    }
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  },
  methods: {
    fillChart () {
      this.chartData = []
      const chartDataArray = []
      const aColors = [
        '#FEC600', '#ff7c43', '#f95d6a', '#d45087',
        '#a05195', '#665191', '#2f4b7c', '#003f5c',
        '#8c510a', '#bf812d', '#dfc27d', '#f6e8c3',
        '#f5f5f5', '#c7eae5', '#80cdc1', '#35978f',
        '#01665e', '#762a83', '#9970ab', '#c2a5cf'
      ]
      // aColors = chroma.scale([chroma('#FEC600').brighten(0).hex(), chroma('#FFF0B2').brighten(0).hex()]).gamma(3).correctLightness().colors(8)
      // aColors = ['#FEC600', '#EE00F7', '#00DEFB', '#F2006B', '#FFF0B2', '#FFBDAD', '#A5ADBA']
      const textColors = [
        '#000', '#fff', '#000', '#fff',
        '#fff', '#fff', '#fff', '#fff',
        '#fff', '#fff', '#000', '#000',
        '#000', '#000', '#fff', '#fff',
        '#fff', '#fff', '#fff', '#000'
      ]
      let aChildren = []

      if (this.apiData && Object.keys(this.apiData).length > 0) {
        let dataRowsArray = []
        if (this.accountType) {
          dataRowsArray = this.apiData[this.accountType].subtype_accounts || {}
        } else {
          dataRowsArray = this.apiData.map(item => item.subtype_accounts).reduce((acc, val) => {
            return [...acc, ...val]
          })
        }

        // Resort to get them in order of size
        const tmpDataRowsArray = {}
        const tmpToSort = []
        for (const index in this.apiData[this.accountType].subtype_sums) {
          tmpToSort.push({ label: index, value: parseInt(this.apiData[this.accountType].subtype_sums[index]) })
        }
        tmpToSort.sort((a, b) => a.value < b.value ? 1 : -1)

        for (const index in dataRowsArray) {
          dataRowsArray[index].sort(function (a, b) {
            return (parseInt(a.balance) < parseInt(b.balance)) ? 1 : -1
          })
        }

        for (const index in tmpToSort) {
          tmpDataRowsArray[tmpToSort[index].label] = dataRowsArray[tmpToSort[index].label]
        }
        // DONE sorting

        for (const index in tmpDataRowsArray) {
          const color = chroma(aColors.shift()).alpha(0.8)
          const textColor = textColors.shift()
          aChildren = []
          for (const index2 in dataRowsArray[index]) {
            aChildren.push({ label: dataRowsArray[index][index2].title, amount: dataRowsArray[index][index2].balance, color: chroma(color).alpha(1.0) })
          }
          chartDataArray.push({
            label: this.$te('common.asset_liabilities.subtypes_plural.' + index) ? this.$t('common.asset_liabilities.subtypes_plural.' + index) : index,
            color: color,
            textColor: textColor,
            children: aChildren
          })
        }
        this.chartData = chartDataArray
      }
    }

  }
}
</script>

<style lang="scss">
  .report-balance-subtypes-doughnut-chart{

  }
</style>
