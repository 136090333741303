<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="assets" :class="{selected: activeTab === 'assets'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_assets') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="assets-detailed" :class="{selected: activeTab === 'assets-detailed'}"  @click="onTabClick">
          {{ $t( translationPath + 'tab_assets_detailed') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="assets-detailed-ext" :class="{selected: activeTab === 'assets-detailed-ext'}"  @click="onTabClick">
          {{ $t( translationPath + 'tab_assets_detailed_ext') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="liabilities" :class="{selected: activeTab === 'liabilities'}"  @click="onTabClick">
          {{ $t( translationPath + 'tab_liabilities') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="liabilities-detailed" :class="{selected: activeTab === 'liabilities-detailed'}"  @click="onTabClick">
          {{ $t( translationPath + 'tab_liabilities_detailed') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="settings" :class="{selected: activeTab === 'settings'}"  @click="onTabClick">
          {{ $t('common.header.header_menu.user_menu.settings') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    translationPath: {},
    active: {
      type: String,
      default: 'assets'
    }
  },
  name: 'AssetLiabilitySubtypesReportSubmenu',
  data () {
    return {
      activeTab: null
    }
  },
  created () {
    this.activeTab = this.active
  },
  methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.activeTab = tab
      this.$emit('tab-switched', tab)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
.main-gradient-header-tab-menu {
  margin-top: 0;
}
</style>
