<template>
  <b-container fluid class="reports-savings-ratio-percentage-data-content-wrapper-detailed-v2">

    <b-row class="" no-gutters>
      <b-col class="mx-3">
        <b-card class="main-gradient-content-card depth-1 mx-xl-auto detailed_table pt-3">
          <template v-slot:header>
            <h2 class="px-3">{{ $t( translationPath + accountType + '.header_title')}} </h2>
          </template>

          <i18n tag="p" class="col-md-8" :path="translationPath + accountType + '.intro_detailed_p1'">
            <b-link slot="assets" to="/reports/other/account-plan-v2/assets">{{$t(translationPath + 'common.your_assets')}}</b-link>
            <b-link slot="liabilities" to="/reports/other/account-plan-v2/liabilities">{{$t(translationPath + 'common.your_liabilities')}}</b-link>
          </i18n>

          <template v-for="(items, keys) in tableData">
            <b-col cols="12" v-bind:key="items.type" v-bind:class="{'mb-4': keys !== tableData.length - 1 }">
              <b-table class="spirecta-simple-table income-accounts-table" show-empty hover responsive striped
                       stacked="sm"
                       :items="items.data"
                       :fields="fieldValues[items.type]"
                       :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(asset_subtype)="row">
                  <template v-if="row.item.type === 'account'">
                    - <b-link :to="'/reports/performance/accounts/'+row.item.id+'/view'" class="font-weight-lighter">{{ row.value }}</b-link>
                  </template>
                  <template v-else-if="row.item.type === 'subtotal'">
                    {{$t('common.subtotal')}}
                  </template>
                  <template v-else>
                    {{ row.value }}
                  </template>
                </template>
                <template v-slot:cell(amount)="row">
                  <template v-if="row.item.type === 'empty_row' || row.item.type === 'category'">
                    &nbsp;
                  </template>
                  <template v-else>
                    {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
                  </template>
                </template>
                <template v-slot:cell(percentage)="row">
                  <template v-if="row.item.type === 'empty_row' || row.item.type === 'category'">
                    &nbsp;
                  </template>
                  <template v-else>
                    {{ row.value }} %
                  </template>
                </template>
              </b-table>
            </b-col>
          </template>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import AssetLiabilitySubtypesReportDataTableDetailed from '../../../../reports/balance/AssetLiabilitySubtypes/AssetLiabilitySubtypesReportDataTableDetailed'
export default {
  name: 'AssetLiabilitySubtypesReportDataTableDetailed',
  extends: AssetLiabilitySubtypesReportDataTableDetailed
}
</script>

<style lang="scss">
  .reports-savings-ratio-percentage-data-content-wrapper-detailed-v2{
    background: transparent;
    .detailed_table {
      border: 5px solid #e1a5a1;
      border-radius: 30px;
      .card-header {
        background: transparent;
        border-bottom: none;
        color: #545c89;
      }
      .card-body {
        p {
          color: #545c89;
        }
      }
    }
    .subtotal_row {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      font-weight: 700;
    }
    thead{display: none;}
    tr.empty_row td{background: white;}
    tr.category_row td{background: white;border-top:0;font-size:120%;}
    tr.subtotal_row td{}
    tr.total_row td{font-size:120%;font-weight:600;border-top:1px solid black;border-bottom:2px solid black;}
    td a{color:black;}
    thead {
      th {
        border-top: none;
      }
    }
  }
</style>
