<template>
  <b-container fluid class="report-balance-subtypes-doughnut-chart-wrapper">
    <sunburst :data="chartData" :key="accountType ? accountType + '_' + currentLang : 'chartData' + '_' + currentLang"/>
  </b-container>
</template>

<script>
import AssetLiabilityDetailedReportDoughnutChart from '../../../../reports/balance/AssetLiabilitySubtypes/AssetLiabilityDetailedReportDoughnutChart'

export default {
  name: 'AssetLiabilityDetailedReportDoughnutChart',
  extends: AssetLiabilityDetailedReportDoughnutChart
}
</script>

<style lang="scss">
  .report-balance-subtypes-doughnut-chart{

  }
</style>
