<template>
  <b-container fluid class="report-balance-subtypes-doughnut-chart-wrapper">
    <doughnut-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'report-balance-subtypes-doughnut-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
      v-if="bOkToLoad"
    >
    </doughnut-chart>

  </b-container>
</template>

<script>
import AssetLiabilitySubtypesReportDoughnutChart from '../../../../reports/balance/AssetLiabilitySubtypes/AssetLiabilitySubtypesReportDoughnutChart'
// import chroma from 'chroma-js'

export default {
  name: 'AssetLiabilitySubtypesReportDoughnutChart',
  extends: AssetLiabilitySubtypesReportDoughnutChart
}
</script>

<style lang="scss">
  .report-balance-subtypes-doughnut-chart{

  }
</style>
