/* eslint-disable */

import * as d3 from 'd3'
import patternify from '../d3-assets/patternify'

function Sunburst (params) {
  var attrs = Object.assign({
    width: 500,
    height: 500,
    margin: {
      top: 20,
      left: 20,
      right: 20,
      bottom: 20
    },
    data: [],
    container: 'body',
    halfPie: false,
    pieStroke: 'transparent',
    minChars: 18
  }, params)

  let initialHeight = attrs.height,
      initialMinCharts = attrs.minChars,
      container,
      svg,
      chart,
      sunburstGroup,
      slicesGroup,
      labelsGroup,
      chartWidth,
      chartHeight,
      radius,
      arc,
      partition,
      format = d3.format(",d"),
      deltaRadius = attrs.halfPie ? Math.PI : 0

  function main() {
    container = d3.select(attrs.container)

    // calculate chart width and height
    setDimensions()

    // initialize required layout such as arc, partition etc.
    setChartParams()

    // add svg and other group elements
    addContainers()

    // draw sunburst
    addSunburst()
  }

  function setChartParams () {
    radius = attrs.halfPie ? Math.min(chartWidth / 2, chartHeight) : Math.min(chartWidth, chartHeight) / 2

    arc = d3.arc()
      .startAngle(d => d.x0 - deltaRadius / 2)
      .endAngle(d => d.x1 - deltaRadius / 2)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0))
      .padRadius(radius / 2)
      .innerRadius(d => d.y0)
      .outerRadius(d => d.y1)

    partition = (data) => {
      const p = d3.partition().size([2 * Math.PI - deltaRadius, radius])
      const h = d3.hierarchy(data)
        .sum(d => {
          return d.amount || 0
        })
        .sort((a, b) => b.amount - a.amount)

      return p(h)
    }
  }

  function setDimensions () {
    const bound = container.node().getBoundingClientRect()
    const width = bound.width

    attrs.minChars = initialMinCharts;
    attrs.height = initialHeight;

    if (width > 0) {
      attrs.width = width
    }

    if (attrs.width < 500) {
      attrs.minChars = 9; 
    } else if (attrs.width < 700) {
      attrs.minChars = 12;
    }

    if (attrs.halfPie && attrs.height > attrs.width / 2) {
      attrs.height = attrs.width / 2;
    } else if (attrs.height > attrs.width) {
      attrs.height = attrs.width;
    }

    chartWidth = attrs.width - attrs.margin.left - attrs.margin.right
    chartHeight = attrs.height - attrs.margin.top - attrs.margin.bottom
  }

  function addContainers () {
    svg = patternify({
      container,
      selector: 'sunburst-chart-svg',
      tag: 'svg'
    })
    .attr('width', attrs.width)
    .attr('height', attrs.height)

    chart = patternify({
      tag: 'g',
      container: svg,
      selector: 'chart-group'
    })
    .attr('transform', `translate(${attrs.margin.left}, ${attrs.margin.top})`)

    sunburstGroup = patternify({
      tag: 'g',
      container: chart,
      selector: 'sunburst-group'
    })
    .attr('transform', `translate(${chartWidth / 2}, ${attrs.halfPie ? chartHeight : chartHeight / 2})`)

    slicesGroup = patternify({
      tag: 'g',
      selector: 'slices-group',
      container: sunburstGroup
    })

    labelsGroup = patternify({
      tag: 'g',
      selector: 'labels-group',
      container: sunburstGroup
    })
    .attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .attr("font-size", 10)
  }

  function addSunburst () {
    const root = partition(attrs.data)

    const slice = patternify({
      tag: 'path',
      selector: 'slice',
      data: root.descendants().filter(d => d.depth),
      container: slicesGroup
    })
    .attr("fill", d => { 
      return d.data.color
    })
    .attr('stroke', attrs.pieStroke)
    .attr('stroke-width', '1px')
    .attr('stroke-opacity', 1)
    .attr("d", arc)

    patternify({
      tag: 'title',
      selector: 'title',
      container: slice,
      data: d => [d]
    })
    .text(d => `${d.ancestors().map(d => d.data.label).reverse().join("/")}\n${format(d.value)}`)
      
    patternify({
      tag: 'text',
      selector: 'label',
      container: labelsGroup,
      data: root.descendants().filter(d => d.depth && (d.y0 + d.y1) / 2 * (d.x1 - d.x0) > 10)
    })
    .attr("transform", d => {
      const x = (d.x0 + d.x1 - deltaRadius) / 2 * 180 / Math.PI
      const y = (d.y0 + d.y1) / 2

      return `rotate(${x - 90}) translate(${y}, 0) rotate(${x > 0 ? 0 : 180})`
    })
    .attr("dy", "0.35em")
    .attr('fill', d => {
      if (d.data.textColor) {
        return d.data.textColor;
      }

      if (d.parent && d.parent.data.textColor) {
        return d.parent.data.textColor;
      }
      
      return '#000';
    })
    .text(d => {
      const label = d.data.label;
      return label.length > attrs.minChars ? label.substr(0, attrs.minChars) + '...' : label;
    })
  }

  main.render = function () {
    main()

    const rand = Math.floor(Math.random() * 1000000000);
    d3.select(window).on(`resize.${rand}`, main);

    return main
  }

  return main
}

export default Sunburst
