<template>
  <div class="main-gradient-header-tab-menu-v2">
    <b-row class="justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="assetsTab" :class="{selected: activeTab === 'assetsTab'}"  @click="onTabClick">
          {{ $t(translationPath + 'tab_assets') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="assetDetailedTab" :class="{selected: activeTab === 'assetDetailedTab'}"  @click="onTabClick">
          {{ $t( translationPath + 'tab_assets_detailed') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="assetDetailedExtTab" :class="{selected: activeTab === 'assetDetailedExtTab'}"  @click="onTabClick">
          {{ $t( translationPath + 'tab_assets_detailed_ext') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="liabilitiesTab" :class="{selected: activeTab === 'liabilitiesTab'}"  @click="onTabClick">
          {{ $t( translationPath + 'tab_liabilities') }}
        </b-link>
        <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="liabilitiesDetailedTab" :class="{selected: activeTab === 'liabilitiesDetailedTab'}"  @click="onTabClick">
          {{ $t( translationPath + 'tab_liabilities_detailed') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AssetLiabilitySubtypesReportSubmenu from '../../../../reports/balance/AssetLiabilitySubtypes/AssetLiabilitySubtypesReportSubmenu'
export default {
  props: {
    translationPath: {},
    active: {
      type: String,
      default: 'percentageTab'
    }
  },
  name: 'AssetLiabilitySubtypesReportSubmenu',
  extends: AssetLiabilitySubtypesReportSubmenu
}
</script>

<style scoped lang="scss">
.main-gradient-header-tab-menu-v2{
  a {
    &.main-gradient-header-tab-menu-tab-item {
      color: #545b7a;
      font-size: 23px;
      line-height: 32px;
      &.selected {
        font-weight: bolder;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
