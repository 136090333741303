<template>
  <b-container fluid class="reports-savings-ratio-percentage-data-content-wrapper px-0">
    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <h2>{{ $t( translationPath + 'table_title_liabilities')}} </h2>
          </template>

          <i18n tag="p" class="col-md-8 pl-0" :path="translationPath + 'assets.intro_summary_p1'">
            <b-link slot="assets" to="/reports/other/account-plan-v2/assets">{{$t(translationPath + 'common.your_assets')}}</b-link>
            <b-link slot="liabilities" to="/reports/other/account-plan-v2/liabilities">{{$t(translationPath + 'common.your_liabilities')}}</b-link>
          </i18n>

          <b-col>
            <b-table class="spirecta-simple-table income-accounts-table" show-empty hover responsive striped
              stacked="sm"
              :items="items"
              :fields="fieldValues"
              :tbody-tr-class="rowClass"
              :empty-text="$t(translationPath + 'common.table_empty_text')"
            >
              <template v-slot:cell(asset_subtype)="row">
                {{ row.value }}
              </template>
              <template v-slot:cell(amount)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
              </template>
              <template v-slot:cell(percentage)="row">
                {{ row.value ? row.value + '%' : row.value }}
              </template>
            </b-table>
          </b-col>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
export default {
  name: 'AssetLiabilitySubtypesReportDataTableLiabilities',
  props: ['apiData'],
  components: {},
  data () {
    return {
      translationPath: 'reports.balance.asset_liability_subtypes_report.',
      dataRows: {}
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    fieldValues () {
      return [
        { key: 'asset_subtype', label: this.$t(this.translationPath + 'common.liability_subtype'), sortable: false, formatter: 'formateName' },
        { key: 'amount', label: this.$t('common.amount'), sortable: false, class: 'text-left text-md-right' },
        { key: 'percentage', label: this.$t('common.percentage'), sortable: false, class: 'text-left text-md-right' }
      ]
    },
    currentLang () {
      return this.$i18n.locale
    },
    items () {
      if (!this.dataRows) return []
      if (Object.keys(this.dataRows).length > 0) {
        const subTypeAssets = Object.hasOwnProperty.call(this.dataRows, 'liabilities') ? this.dataRows.liabilities : []
        const allAccounts = Object.hasOwnProperty.call(subTypeAssets, 'accounts') ? subTypeAssets.accounts : []
        const subTypeAccounts = Object.hasOwnProperty.call(subTypeAssets, 'subtype_accounts') ? subTypeAssets.subtype_accounts : []
        const subTypePercentage = Object.hasOwnProperty.call(subTypeAssets, 'subtype_percentages') ? subTypeAssets.subtype_percentages : {}
        const subTypeSum = Object.hasOwnProperty.call(subTypeAssets, 'subtype_sums') ? subTypeAssets.subtype_sums : {}
        const items = []
        let totals = {
          asset_subtype: 'total',
          amount: 0,
          percentage: 0,
          type: 'total_sum',
          id: null
        }
        const tempSorting = Object.keys(subTypeAccounts).map(keys => {
          return {
            name: this.$te('common.asset_liabilities.subtypes_plural.' + keys) ? this.$t('common.asset_liabilities.subtypes_plural.' + keys) : keys,
            key: keys,
            accounts: allAccounts.filter(items => items.subtype === keys)
          }
        }).sort(function (a, b) {
          const nameA = a.name
          const nameB = b.name
          return nameA.localeCompare(nameB, this.currentLang)
        }.bind(this))
        _.forEach(tempSorting, function (item, key) {
          const index = item.key
          items.push({
            asset_subtype: item.name,
            amount: Object.prototype.hasOwnProperty.call(subTypeSum, index) && subTypeSum[index] ? subTypeSum[index] : 0,
            percentage: Object.prototype.hasOwnProperty.call(subTypePercentage, index) && subTypePercentage[index] ? subTypePercentage[index] : 0,
            type: 'category',
            id: null
          })
          totals = Object.assign(totals, {
            amount: parseFloat(totals.amount) + parseFloat(Object.prototype.hasOwnProperty.call(subTypeSum, index) && subTypeSum[index] ? subTypeSum[index] : 0),
            percentage: parseFloat(totals.percentage) + parseFloat(Object.prototype.hasOwnProperty.call(subTypePercentage, index) && subTypePercentage[index] ? subTypePercentage[index] : 0)
          })
        })
        items.push(totals)
        return items
      }
      return []
    }
  },
  methods: {
    formateName (value) {
      if (!value) return
      if (value === 'total') return this.$t('common.total')
      return value
    },
    rowClass (item) {
      if (!item) return
      if (item.type === 'total' || item.type === 'total_sum') return 'total_row'
    }
  },
  watch: {
    apiData: {
      handler: function () {
        if (this.apiData) {
          this.dataRows = this.apiData || {}
        }
      },
      immediate: true,
      deep: true
    }
  },
  filters: {
    formatAmount
  }
}
</script>

<style lang="scss">
  .reports-savings-ratio-percentage-data-content-wrapper{
    .total_row {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      font-weight: 700;
    }
  }
</style>
