<template>
  <b-container fluid class="report-balance-subtypes-doughnut-chart-wrapper">
    <treemap-nested v-if="chartData !== null" :data="chartData" />
  </b-container>
</template>

<script>
import AssetLiabilityDetailedReportTreemapChart from '../../../../reports/balance/AssetLiabilitySubtypes/AssetLiabilityDetailedReportTreemapChart'

export default {
  name: 'AssetLiabilityDetailedReportTreemapChart',
  extends: AssetLiabilityDetailedReportTreemapChart
}
</script>
