<template>
  <b-container fluid class="reports-savings-ratio-percentage-data-content-wrapper-v2">

    <b-row class="" no-gutters>
      <b-col class="mx-3">
        <b-card class="main-gradient-content-card depth-1 mx-xl-auto assets-table-v2 pt-3">
          <template v-slot:header>
            <h2 class="px-3">{{ $t( translationPath + 'common.asset_subtype')}} </h2>
          </template>

          <i18n tag="p" class="col-md-8" :path="translationPath + 'assets.intro_summary_p1'">
            <b-link slot="assets" to="/reports/other/account-plan-v2/assets">{{$t(translationPath + 'common.your_assets')}}</b-link>
            <b-link slot="liabilities" to="/reports/other/account-plan-v2/liabilities">{{$t(translationPath + 'common.your_liabilities')}}</b-link>
          </i18n>

          <b-col>
            <b-table class="spirecta-simple-table income-accounts-table" show-empty hover responsive striped
              stacked="sm"
              :items="items"
              :fields="fieldValues"
              :tbody-tr-class="rowClass"
            >
              <template v-slot:cell(asset_subtype)="row">
                {{ row.value }}
              </template>
              <template v-slot:cell(amount)="row">
                {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true , 0) }}
              </template>
              <template v-slot:cell(percentage)="row">
                {{ row.value }} %
              </template>
            </b-table>
          </b-col>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import AssetLiabilitySubtypesReportDataTableAssets from '../../../../reports/balance/AssetLiabilitySubtypes/AssetLiabilitySubtypesReportDataTableAssets'
export default {
  name: 'AssetLiabilitySubtypesReportDataTableAssets',
  extends: AssetLiabilitySubtypesReportDataTableAssets
}
</script>

<style lang="scss">
  .reports-savings-ratio-percentage-data-content-wrapper-v2{
    background: transparent;
    .assets-table-v2 {
      border: 5px solid #e1a5a1;
      border-radius: 30px;
      .card-header {
        background: transparent;
        border-bottom: none;
        color: #545c89;
      }
      .card-body {
        p {
          color: #545c89;
        }
      }
    }
    .total_row {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      font-weight: 700;
      background:white;
    }
    table {
      thead {
        th {
          border-top: none;
        }
      }
      tbody {
        tr {
          td {
            font-weight: normal;
            &:nth-child(1) {
              font-weight: normal;
            }
          }
          &:last-child {
            td {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
</style>
