<template>
  <div class="sunburst" ref="sunburst"></div>
</template>

<script>
import Sunburst from './sunburst'

export default {
  name: 'sunburst',
  props: {
    data: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    const halfPie = true
    let height = 850

    if (halfPie) {
      height = 550
    }

    this.chart = Sunburst({
      height: height,
      container: this.$refs.sunburst,
      margin: {
        top: 20,
        left: 20,
        right: 20,
        bottom: 40
      },
      pieStroke: '#fff',
      halfPie: halfPie,
      data: {
        label: '',
        children: [
          ...this.data
        ]
      }
    }).render()
  }
}
</script>
