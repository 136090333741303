<template>
  <b-container fluid class="reports-savings-ratio-percentage-data-content-wrapper-detailed px-0">

    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto ">
          <template v-slot:header>
            <h2>{{ $t( translationPath + accountType + '.header_title')}} </h2>
          </template>

          <i18n tag="p" class="col-md-8 pl-0" :path="translationPath + accountType + '.intro_detailed_p1'">
            <b-link slot="assets" to="/reports/other/account-plan-v2/assets">{{$t(translationPath + 'common.your_assets')}}</b-link>
            <b-link slot="liabilities" to="/reports/other/account-plan-v2/liabilities">{{$t(translationPath + 'common.your_liabilities')}}</b-link>
          </i18n>

          <template v-for="(items, keys) in tableData">
            <b-col cols="12" v-bind:key="items.type" v-bind:class="{'mb-4': keys !== tableData.length - 1 }">
              <b-table class="spirecta-simple-table income-accounts-table" show-empty hover responsive striped
                       stacked="sm"
                       :items="items.data"
                       :fields="fieldValues[items.type]"
                       :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(asset_subtype)="row">
                  <template v-if="row.item.type === 'account'">
                    - <b-link :to="'/reports/performance/accounts/'+row.item.id+'/view'" class="font-weight-lighter">{{ row.value }}</b-link>
                  </template>
                  <template v-else-if="row.item.type === 'subtotal'">
                    {{$t('common.subtotal')}}
                  </template>
                  <template v-else>
                    {{ row.value }}
                  </template>
                </template>
                <template v-slot:cell(amount)="row">
                  <template v-if="row.item.type === 'empty_row' || row.item.type === 'category'">
                    &nbsp;
                  </template>
                  <template v-else>
                    {{ row.value | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 0) }}
                  </template>
                </template>
                <template v-slot:cell(percentage)="row">
                  <template v-if="row.item.type === 'empty_row' || row.item.type === 'category'">
                    &nbsp;
                  </template>
                  <template v-else>
                    {{ row.value }} %
                  </template>
                </template>
              </b-table>
            </b-col>
          </template>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
export default {
  name: 'AssetLiabilitySubtypesReportDataTableDetailed',
  props: ['apiData', 'accountType'],
  components: {},
  data () {
    return {
      translationPath: 'reports.balance.asset_liability_subtypes_report.',
      dataRows: []
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    fieldValues () {
      return {
        assets: [
          { key: 'asset_subtype', label: this.$t(this.translationPath + 'common.asset_subtype'), sortable: false, formatter: 'formateName' },
          { key: 'amount', label: this.$t('common.amount'), sortable: false, class: 'text-left text-md-right' },
          { key: 'percentage', label: this.$t('common.percentage'), sortable: false, class: 'text-left text-md-right' }
        ],
        liabilities: [
          { key: 'asset_subtype', label: this.$t('common.liability_subtype'), sortable: false, formatter: 'formateName' },
          { key: 'amount', label: this.$t('common.amount'), sortable: false, class: 'text-left text-md-right' },
          { key: 'percentage', label: this.$t('common.percentage'), sortable: false, class: 'text-left text-md-right' }
        ]
      }
    },
    currentLang () {
      return this.$i18n.locale
    },
    tableData () {
      if (!this.dataRows) return []
      if (Object.keys(this.dataRows).length > 0) {
        const dataArray = []
        let dataRowsArray = {}
        if (this.accountType) {
          dataRowsArray[this.accountType] = this.dataRows[this.accountType] || []
        } else {
          dataRowsArray = this.dataRows
        }
        _.forEach(dataRowsArray, function (item, itemKeys) {
          const items = []
          const subTypeAssets = Object.hasOwnProperty.call(dataRowsArray, itemKeys) ? dataRowsArray[itemKeys] : []
          const allAccounts = Object.hasOwnProperty.call(subTypeAssets, 'accounts') ? subTypeAssets.accounts : []
          const subTypeAccounts = Object.hasOwnProperty.call(subTypeAssets, 'subtype_accounts') ? subTypeAssets.subtype_accounts : []
          const subTypePercentage = Object.hasOwnProperty.call(subTypeAssets, 'subtype_percentages') ? subTypeAssets.subtype_percentages : {}
          const subTypeSum = Object.hasOwnProperty.call(subTypeAssets, 'subtype_sums') ? subTypeAssets.subtype_sums : {}
          let totals = {
            asset_subtype: 'total',
            amount: 0,
            percentage: 0,
            type: 'total',
            id: null
          }
          const tempSorting = Object.keys(subTypeAccounts).map(keys => {
            return {
              name: this.$te('common.asset_liabilities.subtypes_plural_description.' + keys) ? this.$t('common.asset_liabilities.subtypes_plural_description.' + keys) : keys,
              key: keys,
              accounts: allAccounts.filter(items => items.subtype === keys).sort(function (a, b) {
                const nameA = a.title
                const nameB = b.title
                return nameA.localeCompare(nameB, this.currentLang)
              }.bind(this))
            }
          }).sort(function (a, b) {
            const nameA = a.name
            const nameB = b.name
            return nameA.localeCompare(nameB, this.currentLang)
          }.bind(this))
          _.forEach(tempSorting, function (itemData, key) {
            const index = itemData.key
            items.push({
              asset_subtype: itemData.name,
              amount: Object.prototype.hasOwnProperty.call(subTypeSum, index) && subTypeSum[index] ? subTypeSum[index] : 0,
              percentage: Object.prototype.hasOwnProperty.call(subTypePercentage, index) && subTypePercentage[index] ? subTypePercentage[index].toFixed(1) : 0,
              type: 'category',
              id: null
            })
            _.forEach(itemData.accounts, function (AccItems) {
              items.push({
                asset_subtype: AccItems.title,
                amount: AccItems.balance,
                percentage: AccItems.percentage_of_total,
                type: 'account',
                id: AccItems.id
              })
            })
            items.push({
              asset_subtype: 'common.subtotal',
              amount: Object.prototype.hasOwnProperty.call(subTypeSum, index) && subTypeSum[index] ? subTypeSum[index] : 0,
              percentage: Object.prototype.hasOwnProperty.call(subTypePercentage, index) && subTypePercentage[index] ? subTypePercentage[index].toFixed(1) : 0,
              type: 'subtotal',
              id: null
            })
            items.push({
              asset_subtype: null,
              amount: 0,
              percentage: 0,
              type: 'empty_row',
              id: null
            })
            totals = Object.assign(totals, {
              type: 'total',
              amount: parseFloat(totals.amount) + parseFloat(Object.prototype.hasOwnProperty.call(subTypeSum, index) && subTypeSum[index] ? subTypeSum[index] : 0),
              percentage: Number.parseFloat(parseFloat(totals.percentage) + parseFloat(Object.prototype.hasOwnProperty.call(subTypePercentage, index) && subTypePercentage[index].toFixed(1) ? subTypePercentage[index] : 0)).toFixed(0)
            })
          })
          items.push(totals)
          dataArray.push({
            type: itemKeys,
            data: items
          })
        }.bind(this))
        return dataArray
      }
      return []
    }
  },
  methods: {
    formateName (value) {
      if (!value) return
      if (value === 'total') return this.$t('common.total')
      return value
    },
    rowClass (item) {
      if (!item) return
      if (item.type === 'subtotal') return 'subtotal_row'
      if (item.type === 'empty_row') return 'empty_row'
      if (item.type === 'category') return 'category_row'
      if (item.type === 'total') return 'total_row'
    }
  },
  watch: {
    apiData: {
      handler: function () {
        if (this.apiData) {
          this.dataRows = this.apiData || {}
        }
      },
      immediate: true,
      deep: true
    }
  },
  filters: {
    formatAmount
  }
}
</script>

<style lang="scss">
  .reports-savings-ratio-percentage-data-content-wrapper-detailed{
    .subtotal_row {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      font-weight: 700;
    }
    thead{display: none;}
    tr.empty_row td{background: white;}
    tr.category_row td{background: white;border-top:0;font-size:120%;}
    tr.subtotal_row td{}
    tr.total_row td{font-size:120%;font-weight:600;border-top:1px solid black;border-bottom:2px solid black;}
    td a{color:black;}
    thead {
      th {
        border-top: none;
      }
    }
  }
</style>
