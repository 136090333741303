<template>
  <b-container fluid class="report-balance-subtypes-doughnut-chart-wrapper">
    <template v-if="!apiData || Array.isArray(this.apiData) || Object.keys(apiData).length === 0">
      <empty-graph :message="$t('incomes_expenses.accounts.view.no_transaction_no_chart')"/>
    </template>
    <template v-else>
      <treemap-nested :data="chartData" />
    </template>
  </b-container>
</template>

<script>
import treemapNested from '@/components/d3charts/treemap-nested/treemap-nested.vue'
import EmptyGraph from '@/components/common/EmptyGraph'

export default {
  name: 'AssetLiabilityDetailedReportTreemapChart',
  components: { treemapNested, EmptyGraph },
  props: ['apiData'],
  data () {
    return {
      chartData: null,
      translationPath: 'reports.balance.asset_liability_subtypes_report.'
    }
  },
  methods: {
    fillChart () {
      if (this.apiData === null || (typeof this.apiData === 'object' && !Object.prototype.hasOwnProperty.call(this.apiData, 'assets'))) {
        return false
      }

      const colors = [
        '#FEC600', '#ff7c43', '#f95d6a', '#d45087',
        '#a05195', '#665191', '#2f4b7c', '#003f5c',
        '#8c510a', '#bf812d', '#dfc27d', '#f6e8c3',
        '#f5f5f5', '#c7eae5', '#80cdc1', '#35978f',
        '#01665e', '#762a83', '#9970ab', '#c2a5cf'
      ]
      const textColors = [
        '#000', '#fff', '#000', '#fff',
        '#fff', '#fff', '#fff', '#fff',
        '#fff', '#fff', '#000', '#000',
        '#000', '#000', '#fff', '#fff',
        '#fff', '#fff', '#fff', '#000'
      ]

      const data = []
      const accountsTyped = {}
      const accountSums = []
      this.apiData.assets.accounts.map((account) => {
        if (typeof accountsTyped[account.subtype] === 'undefined') {
          accountsTyped[account.subtype] = []
        }
        accountsTyped[account.subtype].push(account)
      })

      /* sort so we get same as doughnut */
      for (const subtype in accountsTyped) {
        accountSums.push({
          label: subtype,
          amount: accountsTyped[subtype].reduce((a, b) => a + (parseFloat(b.balance) || 0), 0)
        })
      }
      accountSums.sort((a, b) => a.amount < b.amount ? 1 : -1)

      let i = 0
      let subtype = null
      for (const idx in accountSums) {
        subtype = accountSums[idx].label

        const itemSubtype = {
          label: this.$t('common.asset_liabilities.subtypes.' + subtype.replace(/(_)$/g, '')),
          color: colors[i],
          textColor: textColors[i],
          children: []
        }

        accountsTyped[subtype].map((account) => {
          const itemAccount = {
            label: account.title,
            color: colors[i],
            textColor: textColors[i],
            amount: parseFloat(account.balance)
          }
          itemSubtype.children.push(itemAccount)
        })

        data.push(itemSubtype)
        i++
      }

      this.chartData = data
    }
  },
  watch: {
    apiData: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
