<template>
  <div class="report-balance-asset-liability-subtypes-wrapper-v2">

    <!-- START GRADIENT HEADER -->
    <b-container class="mainChart pt-5" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper">

        <b-row class="breadcrumb-wrapper" no-gutters>

          <b-col no-gutters>
            <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
            <h1 class="text-left mb-3">{{ $t(translationPath + 'title') }}</h1>
          </b-col>

        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-5" no-gutters>
          <b-col no-gutters>
            <asset-liability-subtypes-report-submenu :translationPath="this.translationPath" @tab-switched="onTabSwitched" :active="this.activeTab"></asset-liability-subtypes-report-submenu>
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper">
        <div class="pb-5" :style="chartWrapperStyle">
          <asset-liability-subtypes-report-doughnut-chart :apiData="this.apiData" :currentCOA="currentCOA" :busy="busyState.chart" accountType="assets" v-if="this.activeTab === 'assetsTab'"></asset-liability-subtypes-report-doughnut-chart>
          <asset-liability-subtypes-report-doughnut-chart :apiData="this.apiData" :currentCOA="currentCOA" :busy="busyState.chart" accountType="liabilities" v-if="this.activeTab === 'liabilitiesTab'"></asset-liability-subtypes-report-doughnut-chart>
          <!--AssetLiabilityDetailedReportDoughnutChart-->
          <asset-liability-detailed-report-doughnut-chart :apiData="this.apiData" :currentCOA="currentCOA" :busy="busyState.chart" v-if="this.activeTab === 'assetDetailedTab' || this.activeTab === 'liabilitiesDetailedTab'" :accountType="this.activeTab === 'assetDetailedTab' ? 'assets' : 'liabilities'"></asset-liability-detailed-report-doughnut-chart>
          <asset-liability-detailed-report-treemap-chart
            v-if="activeTab === 'assetDetailedExtTab'"
            :api-data="apiData"
          ></asset-liability-detailed-report-treemap-chart>
        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0">
      <asset-liability-subtypes-report-data-table-assets :apiData="this.apiData" :currentCOA="currentCOA" :busy="busyState.chart" v-if="this.activeTab === 'assetsTab'"></asset-liability-subtypes-report-data-table-assets>
      <asset-liability-subtypes-report-data-table-liabilities :apiData="this.apiData" :currentCOA="currentCOA" :busy="busyState.chart" v-if="this.activeTab === 'liabilitiesTab'"></asset-liability-subtypes-report-data-table-liabilities>
      <asset-liability-subtypes-report-data-table-detailed
        v-if="['assetDetailedTab', 'assetDetailedExtTab', 'liabilitiesDetailedTab'].indexOf(this.activeTab) !== -1"
        :apiData="this.apiData" :busy="busyState.chart"
        :accountType="this.activeTab === 'liabilityDetailedTab' ? 'liabilities' : 'assets'"
      ></asset-liability-subtypes-report-data-table-detailed>
    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import AssetLiabilitySubtypesReportSubmenu from './AssetLiabilitySubtypesReportSubmenu'
import AssetLiabilitySubtypesReportDoughnutChart from './AssetLiabilitySubtypesReportDoughnutChart'
import AssetLiabilityDetailedReportDoughnutChart from './AssetLiabilityDetailedReportDoughnutChart'
import AssetLiabilityDetailedReportTreemapChart from './AssetLiabilityDetailedReportTreemapChart'
import AssetLiabilitySubtypesReportDataTableAssets from './AssetLiabilitySubtypesReportDataTableAssets'
import AssetLiabilitySubtypesReportDataTableLiabilities from './AssetLiabilitySubtypesReportDataTableLiabilities'
import AssetLiabilitySubtypesReportDataTableDetailed from './AssetLiabilitySubtypesReportDataTableDetailed'

export default {
  name: 'Report',
  mixins: [titleMixins],
  components: { AssetLiabilitySubtypesReportSubmenu, AssetLiabilitySubtypesReportDoughnutChart, AssetLiabilitySubtypesReportDataTableAssets, AssetLiabilitySubtypesReportDataTableLiabilities, AssetLiabilitySubtypesReportDataTableDetailed, AssetLiabilityDetailedReportDoughnutChart, AssetLiabilityDetailedReportTreemapChart },
  data () {
    return {
      start_date: null,
      end_date: null,
      apiData: [],
      activeTab: 'assetsTab',
      busyState: {
        chart: true,
        table: true
      },
      translationPath: 'reports.balance.asset_liability_subtypes_report.'
    }
  },
  mounted () {
    this.fetchApiData()

    this.$bvToast.toast(this.$t('common.toasts.loading_data'), {
      title: this.$t(this.translationPath + 'title'),
      variant: 'info',
      solid: true
    })
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('reports._common.balance_reports'), to: '/reports/balance' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    chartWrapperStyle () {
      const style = {
        width: '100%',
        height: '500px'
      }
      switch (this.activeTab) {
        case 'assetDetailedTab':
        case 'liabilitiesDetailedTab':
          style.height = '615px'
          break
        case 'assetDetailedExtTab':
          style.height = '700px'
          break
      }
      return style
    }
  },
  async created () {

  },
  methods: {
    fetchApiData () {
      this.busyState.chart = true
      this.busyState.table = true

      // http://api.spirecta.test/api/v1/reports/balance/asset-types
      const ApiEndpoint = process.env.VUE_APP_ROOT_API + '/reports/balance/subtypes'

      return axios.get(ApiEndpoint)
        .then(response => response.data)
        .then(responseData => {
          this.apiData = Object.prototype.hasOwnProperty.call(responseData, 'data') ? responseData.data : []
          this.busyState.chart = false
          this.busyState.table = false
          return true
        })
        .catch(err => {
          console.error(err)
          return false
        })
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
    }
  },
  watch: {
    start_date: function (newVal, oldVal) {
      if (this.showToastOnYearChange) {
        this.$bvToast.toast(this.$t('common.toasts.loading_data_for_period', { start_date: this.start_date, end_date: this.end_date }), {
          title: this.$t(this.translationPath + 'title'),
          variant: 'info',
          solid: true
        })
      } else {
        this.showToastOnYearChange = true
      }

      if (newVal) {
        this.fetchApiData().then(status => {
          this.$bvToast.toast(this.$t('common.toasts.data_for_period_is_now_loaded', { start_date: this.start_date, end_date: this.end_date }), {
            title: this.$t(this.translationPath + 'title'),
            variant: 'success',
            solid: true
          })
        })
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
  .report-balance-asset-liability-subtypes-wrapper-v2
  {
    width: 100%;
    .mainChart {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      background: #f4f4f3;
      h1 {
        color: #545b7a;
      }
      .breadcrumb {
        color: #545b7a;
        font-weight: bolder;
        .breadcrumb-item {
          opacity: 1;
          a {
            color: #545b7a;
            font-weight: bolder;
          }
        }
      }
    }
    .main-content-wrapper {
      background: transparent;
    }
  }
</style>
